
import MDAlert from "./components/MDAlert";
import history from './myHistory.js'
import {api} from "./assets/boot/axios";


async function apiGet(path) {
    let data;
    await api.get(`${path}`)
        .then(response => {
            data = response.data
        })
        .catch(error => {
            alertError(error, path);
        })
    return data
}

async function apiPost(path, payload, headers) {
    let data;
    if (headers === undefined) {
        await api.post(`${path}`, payload)
            .then(response => {
                data = response
            })
            .catch(error => {
                alertError(error, path)
            })
        return data
    } else {
        await api.post(`${path}`, payload, headers)
            .then(response => {
                data = response
            })
            .catch(error => {
                alertError(error, path)
            })
        return data
    }
}



function alertError(error, path) {
    console.log("error.code", error.code);
    if (error.code === 'ERR_NETWORK') {
            console.log('Microservices catch:', error)
        return (
            <MDAlert color="error" dismissible>
                Internal server error.
                Die Microservices sind nicht erreichbar.
                Bitte versuchen Sie es später erneut.
            </MDAlert>
        )
    } else if (error.code === 'ERR_BAD_REQUEST') {
        if (error.response.status === 401) {
            console.log('401 catch:', error)
            localStorage.removeItem("AUTH_TOKEN");
            history.push('/')
            history.go()
            // return (
            //     <MDAlert color="error" dismissible>
            //         Session abgelaufen.
            //         Bitte melden Sie sich erneut an
            //     </MDAlert>
            // )
            // test("/");

        }
    } else if (error.code === 'ERR_BAD_RESPONSE') {
        if (error.response.status === 500) {
            console.log('500 catch:', error)
            return (
                <MDAlert color="error" dismissible>
                    Internal server error.
                    Bitte versuchen Sie es später erneut.
                </MDAlert>
            )
        }
    }

}


export {apiGet, apiPost}

