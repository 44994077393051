import axios from 'axios'

const api = axios.create({
     baseURL: process.env.REACT_APP_API_URL,
     headers: {
         'Content-Type': 'application/json'
     }
 });



const AUTH_TOKEN = localStorage.getItem('AUTH_TOKEN')
api.defaults.headers.common['Authorization'] = "Bearer "+ AUTH_TOKEN;
console.log("process.env.REACT_APP_API_URL" + process.env.REACT_APP_API_URL);
export { api }
