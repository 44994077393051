import * as React from 'react';
import {
    Alert, AlertTitle,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material";
import Button from "@mui/material/Button";
import MDInput from "../../components/MDInput";
import Grid from "@mui/material/Grid";
import "assets/css/style.css"
import {useEffect, useState} from "react";
import FormField from "./Component/FormField";
import Card from "@mui/material/Card";
import MDBox from "../../components/MDBox";
import Autocomplete from "@mui/material/Autocomplete";
import selectData from "../../components/selectData";
import Switch from "@mui/material/Switch";
import sales from "../dashboards/sales";
import {siLK} from "@mui/material/locale";
import {api} from "../../assets/boot/axios";
import Box from "@mui/material/Box";



const DialogPDFDownload = (props) => {
    return (
        <div>
            <Dialog open={props.show} onClose={props.close}>
                <DialogContent component="form">
                        <Box sx={{ display: 'flex' , justifyContent: 'center', paddingBottom: '2rem', paddingTop: '1rem'}}>
                            <CircularProgress />
                        </Box>
                    <Box sx={{ display: 'block', textAlign:'center', paddingBottom: '1rem'}}>
                        <DialogContentText>Bitte warten Sie einen Moment</DialogContentText>
                        <DialogContentText>Die PDF wird gerade heruntergeladen</DialogContentText>
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default DialogPDFDownload;

