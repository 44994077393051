/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import {useEffect, useState} from "react";
import MDButton from "../../components/MDButton";
import Icon from "@mui/material/Icon";
import DialogEdit from "./dialog";
import DialogDelet from "./dialogDelet";
import DialogAdd from "./dialogAdd";
import {apiGet, apiPost} from "../../apiGet";
import {api} from "../../assets/boot/axios";
import DialogPDFDownload from "./dialogPDFDownload";
import DialogPDFMiss from "./dialogPDFMiss";
import {useLocation} from "react-router-dom";

import FormDialog from "./dialogCancelCause";
import Button from "@mui/material/Button";
import InfoCauseForCancel from "./dialogInfoCauseForCancel";

async function GetData() {
    return await api.get('/booking/getAll');
}
function Booking() {
    const [data, setData] = useState([]);
    const [dialogAdd, setDialogAdd] = useState(false);

    const location = useLocation();

    useEffect(() => {
        getDataByPath()
    }, [location]);

    useEffect(() => {
        getDataByPath()
    }, []);

    const getDataByPath = () => {
         if(location.pathname === "/BuchungenAktiv"){
            GetData().then(result => {
                let filtered_result = result.data.filter(d => d.isCanceled === 0)
                setData(filtered_result.reverse());
            })
        }else if(location.pathname === "/BuchungenArchiviert"){
            GetData().then(result => {
                let filtered_result = result.data.filter(d => d.isCanceled === 1 &&  d.bookingNumber != undefined && !d.bookingNumber.startsWith("S-"))
                setData(filtered_result.reverse());
            })
        }else if(location.pathname === "/BuchungenStorniert"){
            GetData().then(result => {
                let filtered_result = result.data.filter(d => d.isCanceled === 1 &&  d.bookingNumber != undefined && d.bookingNumber.startsWith("S-"))
                setData(filtered_result.reverse());
            })
        }else if(location.pathname === "/Buchungen"){
            GetData().then(result => {
                let filtered_result = result.data.reverse()
                setData(filtered_result);
            })
        }
    }

    const dataTableCalculation = {
        status: "hello",
        columns: [
            {Header: "Status", accessor: "status", Cell: row => (
                    <div>
                        {TableStatus(row.row.original)}
                    </div>
                ),
            },
            {Header: "Vorname", accessor: "firstName", width: "20%"},
            {Header: "Nachname", accessor: "lastName", width: "20%"},
            {Header: "Ferienhaus", accessor: "type", width: "25%"},
            {Header: "Anzahlung", accessor: "deposit", Cell: row => (
                    <div>
                        {TabelPayment(row.row.original)}
                    </div>
                ),
            },
            {Header: "Mietbeginn", accessor: "rentStart", width: "7%"},
            {Header: "Mietende", accessor: "rentEnd"},
            {Header: "Rest Betrag", accessor: "restPayment", Cell: row => (
                    <div>
                        {TabelRestPayment(row.row.original)}
                    </div>
                ),
            },
            {
                Header: "Action", accessor: "actions", Cell: row => (
                    <div>
                        {ActionButtons(row.row.original)}
                    </div>
                ),
            },
        ],
        rows: data
    };
    const addObj = (newObj) => {
        api.post(`/booking/add`, newObj)
            .then(response => {
                console.log(response)
                setData([response.data, ...data])
                setDialogAdd(false)
            })
            .catch(error => {
                console.error("error", error);
                // alertError(error, path)
            })
    }
    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <MDBox pt={6} pb={3}>
                <Card>
                    <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
                        <MDTypography variant="h5" fontWeight="medium">
                            Buchungen
                        </MDTypography>
                        <MDButton variant="gradient" color="dark" onClick={() => setDialogAdd(true)} title="Neue Berechnung">
                            <Icon sx={{fontWeight: "bold"}}>add</Icon>
                            &nbsp;Neue Buchung
                        </MDButton>
                    </MDBox>
                    <DataTable table={dataTableCalculation} canSearch/>
                </Card>
            </MDBox>
            { dialogAdd && <DialogAdd show={dialogAdd} save={addObj} close={() => setDialogAdd(false)}></DialogAdd> }
            <Footer/>
        </DashboardLayout>
    );

    function ActionButtons(row) {
        const [open, setOpen] = useState(false);
        const [dialogDelete, setDialogDelete] = useState(false);
        const [dialogPdfDownload, setDialogPdfDownload] = useState(false);
        const [dialogPdfMiss, setDialogPdfMiss] = useState(false);
        const [editMoneyMode, setEditMoneyMode] = useState(false);

        const fileDownload = null;

        function moneyModal(open) {
            setEditMoneyMode(open);
            setOpen(open);
        }
        const save = (editObj, oldObj) => {
            if (editObj.amountOfDog > 0) {
                editObj.dog = true
            } else {
                editObj.dog = false
            }
            let finalEditObj = {
                ...oldObj,
                ...editObj
            };

            api.post(`/booking/edit/` + editObj.id, finalEditObj)
                .then(response => {
                    setOpen(false)
                    setEditMoneyMode(false)
                    setData([...data.map(d => d.id===editObj.id ? response.data : d)])
                })
                .catch(error => {
                    console.error("error", error);
                    // alertError(error, path)
                })
        };
        const deleteObj = (obj) => {
            api.post(`/booking/disable/` + obj.id)
                .then(response => {
                    console.log("deleting obj: ", obj)
                    console.log(obj)
                    if(location.pathname === "/BuchungenAktiv"){
                        setData([...data.filter(d => d.id != obj.id)])
                    }
                    setDialogDelete(false)
                })
                .catch(error => {
                    console.error("error", error);
                })
        };

        const downloadFile = async (props) => {
            setDialogPdfDownload(true)
            let rowId = props.id

            try{
                const response = await apiGet(`/export/bill/` + rowId)
                let fileDownload = convertInBlob(response.file)
                downloadFilePdf(fileDownload, response.name);
                setDialogPdfDownload(false)

                const responseNew = await apiGet('/booking/' + rowId)
                if(location.pathname === "/BuchungenAktiv"){
                    setData([...data.map( d => d.id === responseNew.id ? responseNew : d)])
                }
            }catch(e){
                console.error("error", e);
                setDialogPdfDownload(false)
                setDialogPdfMiss(true)
            }
        }

        const copyBooking = (props) => {
            let rowId = props.id

            apiPost(`booking/clone/${rowId}`).then(response => {
                setData([...data, response ])
                //getDataByPath()
            }).catch(error => {
                console.error("error", error);
            })
        }

        const cancelBooking = (props, cause) => {
            let rowId = props.id
            apiPost(`booking/cancel/${rowId}`, {cause: cause }, {headers: {'Content-Type': 'application/json'}})
                .then(response => {
                    if(location.pathname === "/BuchungenAktiv"){
                        setData([...data.filter(d => d.id !== rowId)])
                    }
            }).catch(error => {
                console.error("error", error);
            })
        }

        const downloadFileInvoice = (props) => {
            let rowId = props.id
            //todo api später auf richtige anfrage ändern

            apiGet(`/export/confirmation/`).then(response => {
                // let fileDownload = convertInBlob(response)
                // console.log('fileDownload', fileDownload)
                // downloadFilePdf(fileDownload, "myfile.pdf");
            }).catch(error => {
                console.error("error", error);
            })
        }


        const downloadFilePdf = (blob, fileName) => {
            const link = document.createElement('a');
            link.href = blob;
            link.download = fileName;
            document.body.append(link);
            link.click();
            link.remove();
            setTimeout(() => URL.revokeObjectURL(link.href), 7000);
        };

        function convertInBlob(base64) {
            base64 = base64.replace('data:application/pdf;base64,', '')
            const contentType = 'application/pdf';
            const sliceSize = 512;

            const byteCharacters = atob(base64);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                const slice = byteCharacters.slice(offset, offset + sliceSize);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            const blob = new Blob(byteArrays, {type: contentType});
            const blobUrl = URL.createObjectURL(blob);
            return blobUrl;
        }

        return (
            <div>
                {(row.isCanceled !== 0 || row.pdfGenerated !== 1) ?
                    <MDButton variant="text" color="info" disabled={row.isCanceled === 1 || row.pdfGenerated === 1} iconOnly onClick={() => {setEditMoneyMode(false);setOpen(true)}} title="Bearbeiten">
                        <Icon>edit </Icon>
                    </MDButton>
                    :
                    <MDButton variant="text" color="info" iconOnly onClick={() => moneyModal(true)} title="Bearbeiten">
                        <Icon>euro </Icon>
                    </MDButton>}
                <MDButton variant="text" color="info" disabled={row.isCanceled === 1 && (row.isCanceled === 1 && row.bookingNumber.includes("S-"))} iconOnly onClick={() => copyBooking(row)} title="Kopieren">
                    <Icon>copy</Icon>
                </MDButton>
                <MDButton variant="text" color="info" disabled={row.isCanceled === 1} iconOnly onClick={() => setDialogDelete(true)} title="Löschen">
                    <Icon>delete</Icon>
                </MDButton>

                {/*Cancel button ist hier drin */}
                <FormDialog handleSubmit={cancelBooking} row={row}> </FormDialog>
                <InfoCauseForCancel row={row}></InfoCauseForCancel>

                <MDButton variant="text" color="info" disabled={row.rentStart === null && row.rentEnd === null} row={row} iconOnly onClick={() => downloadFile(row)} title="PDF Downloaden">
                    <Icon>picture_as_pdf</Icon>
                </MDButton>
                { open && <DialogEdit show={open} moneyOnly={editMoneyMode} row={row} save={save} close={() => moneyModal(false)}></DialogEdit> }
                <DialogDelet show={dialogDelete} row={row} save={deleteObj}
                             close={() => setDialogDelete(false)}></DialogDelet>
                <DialogPDFDownload show={dialogPdfDownload} close={!dialogPdfDownload} ></DialogPDFDownload>
                <DialogPDFMiss show={dialogPdfMiss} close={() => setDialogPdfMiss(false)}></DialogPDFMiss>
            </div>
        )
    }

}



function TabelPayment(row){
    let paymentIcon
    if (row.firstPayment >= row.deposit){
        paymentIcon =  <Icon color='success' fontSize='small'>check</Icon>
    } else{
        paymentIcon = null
    }

    return (
        <div style={{display: "flex", textAlign:"center"}}>
            {paymentIcon}
            <span >{row.deposit}</span>
        </div>
    )
}
function TableStatus(row){
    // Check: Normal State
    // Blue Flag: Missing Input
    // Circle Canceled: Is the original canceled Booking
    // Shield Canceled: Is the copy of a canceled Booking, now with "S-" in the contract
    let rowIcon = null;
    if(row.rentEnd === null || row.rentStart === null) {
        rowIcon =  <><Icon color="info" fontSize='small'>flag</Icon> <strong style={{fontSize: 12, paddingLeft: '5px'}}>Hinweis</strong></>
    } else if (row.isCanceled && row.bookingNumber !== null){
        if (row.bookingNumber.includes("S-")) {
            rowIcon = <> <Icon color="error" fontSize='small'>gpp_bad</Icon><strong style={{fontSize: 12, paddingLeft: '5px'}}>Storniert</strong></>
        } else {
            rowIcon = <> <Icon color="error" fontSize='small'>cancel</Icon><strong style={{fontSize: 12, paddingLeft: '5px'}}>Archiviert</strong></>
        }
    } else {
        rowIcon =  <><Icon color='success' fontSize='small'>check</Icon></>
    }

    return (
        <div style={{display: "flex", textAlign:"center"}}>
            {rowIcon}
        </div>
    )
}
function TabelRestPayment(row){
    //TODO
    let roundRow = row.restPayment.toFixed(2)
    return (
            <span >{roundRow}</span>
    )
}

export default Booking;
