import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MDButton from "../../components/MDButton";
import Icon from "@mui/material/Icon";

export default function InfoCauseForCancel({ row }) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        console.log(row)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <MDButton variant="text" color="info" iconOnly onClick={handleClickOpen} title="Stonieren" disabled={!(row.isCanceled === 1 && row.pdfGenerated === 1)}>
                <Icon>info</Icon>
            </MDButton>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries((formData).entries());
                        const email = formJson.email;
                        console.log(email);
                        handleClose();
                    },
                }}
            >
                <DialogTitle>Stornierungsgrund</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Grund für Stornierung:
                    </DialogContentText>
                    { () => {
                        console.log(row)} }
                    {  row.causeForCancel ? row.causeForCancel : "leer..." }
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}