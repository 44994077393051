/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

// react-router-dom components
import {Link} from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/login/component/BasicLayout/index";

// Images
import bgImage from "assets/images/bg-reset-cover.jpeg";
import {api} from "../../assets/boot/axios";
import {apiPost} from "../../apiGet";

function LoginPage() {
	const [memberData, setMemberData] = useState({
													 username: "",
													 password: "",
												 })


	const navigate = useNavigate();

	function handleChange(evt) {
		const value = evt.target.value;
		setMemberData({
						  ...memberData,
						  [evt.target.name]: value
					  });
	}

	async function login() {
		await apiPost('/auth/login/', memberData).then(res => {
			if(res.data.accessToken) {
				localStorage.setItem('AUTH_TOKEN',res.data.accessToken);
				api.defaults.headers.common['Authorization'] = "Bearer "+ res.data.accessToken;
				navigate("/Dashboard");
			}
		}).catch(error => {
			delete api.defaults.headers.common["Authorization"];
			console.log("Error: ", error)
		})
	}

	return (
		<BasicLayout image={bgImage}>
			<Card>
				<MDBox
					variant="gradient"
					bgColor="info"
					borderRadius="lg"
					coloredShadow="info"
					mx={2}
					mt={-3}
					p={2}
					mb={1}
					textAlign="center"
				>
					<MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
						Einloggen
					</MDTypography>
				</MDBox>
				<MDBox pt={4} pb={3} px={3}>
					<MDBox component="form" role="form">
						<MDBox mb={2}>
							<MDInput type="text" label="Benutzername" fullWidth name="username" onChange={handleChange}
									 value={memberData.username}/>
						</MDBox>
						<MDBox mb={2}>
							<MDInput type="password" label="Password" fullWidth name="password" onChange={handleChange}
									 value={memberData.password}/>
						</MDBox>
						{/*<MDBox display="flex" alignItems="center" ml={-1}>*/}
						{/*	<Switch checked={rememberMe} onChange={handleSetRememberMe}/>*/}
						{/*	<MDTypography*/}
						{/*		variant="button"*/}
						{/*		fontWeight="regular"*/}
						{/*		color="text"*/}
						{/*		onClick={handleSetRememberMe}*/}
						{/*		sx={{cursor: "pointer", userSelect: "none", ml: -1}}*/}
						{/*	>*/}
						{/*		&nbsp;&nbsp;Angemeldet bleiben*/}
						{/*	</MDTypography>*/}
						{/*</MDBox>*/}
						<MDBox mt={4} mb={1}>
							<MDButton variant="gradient" color="info" fullWidth onClick={login}>
								Einloggen
							</MDButton>
						</MDBox>
						{/*<MDBox mt={3} mb={1} textAlign="center">*/}
						{/*	<MDTypography variant="button" color="text">*/}
						{/*		<MDTypography*/}
						{/*			component={Link}*/}
						{/*			to="/authentication/sign-up/cover"*/}
						{/*			variant="button"*/}
						{/*			color="info"*/}
						{/*			fontWeight="medium"*/}
						{/*			textGradient*/}
						{/*		>*/}
						{/*			Ich habe mein Passwort vergessen*/}
						{/*		</MDTypography>*/}
						{/*	</MDTypography>*/}
						{/*</MDBox>*/}
						{/*<MDBox mt={3} mb={1} textAlign="center">*/}
						{/*	<MDTypography variant="button" color="text">*/}
						{/*		Hast du noch kein Konto?{" "}*/}
						{/*		<MDTypography*/}
						{/*			component={Link}*/}
						{/*			to="/authentication/sign-up/cover"*/}
						{/*			variant="button"*/}
						{/*			color="info"*/}
						{/*			fontWeight="medium"*/}
						{/*			textGradient*/}
						{/*		>*/}
						{/*			Anmelden*/}
						{/*		</MDTypography>*/}
						{/*	</MDTypography>*/}
						{/*</MDBox>*/}
					</MDBox>
				</MDBox>
			</Card>
		</BasicLayout>
	);
}

export default LoginPage;
