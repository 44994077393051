import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MDButton from "../../components/MDButton";
import Icon from "@mui/material/Icon";

export default function FormDialog({ handleSubmit, row }) {
    const [open, setOpen] = React.useState(false);

    const [value, setValue] = React.useState("");

    const max_len = 255

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setValue("")
        setOpen(false);
    };

    const handleChange = name => event => {
      setValue(event.target.value);
    };

    return (
        <React.Fragment>
            <MDButton variant="text" color="info" disabled={row.isCanceled === 1 || row.pdfGenerated === 0} iconOnly onClick={handleClickOpen} title="Stonieren">
                    <Icon>cancel</Icon>
            </MDButton>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries((formData).entries());
                        const email = formJson.email;
                        console.log(email);
                        handleClose();
                    },
                }}
            >
                <DialogTitle>Stornierung</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Bitte den Grund für die Stornierung angeben.
                    </DialogContentText>
                    <TextField
                        multiline
                        rows={5}
                        value={value}
                        helperText={`${value.length}/${max_len}`}
                        onChange={handleChange("name")}
                        inputProps={{
                            maxlength: max_len
                        }}
                        autoFocus
                        required
                        margin="dense"
                        id="cause"
                        name="cause"
                        label="Grund"
                        type="text"
                        fullWidth
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    <Button style={{ color: '#000'}} color="secondary" variant="outlined" onClick={handleClose}>Abbrechen</Button>
                    <Button style={{ color: '#000'}} color="primary" variant="contained" type="submit" onClick={() => handleSubmit(row, value)}>Stornieren</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}