/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import {createRoot} from "react-dom/client";
// DANIEL Chart nicht rausschmeißen!
import Chart from 'chart.js/auto';

// Material Dashboard 2 PRO React Context Provider
import { MaterialUIControllerProvider } from "context";
import {noScrollInput} from "./helper/helper";

noScrollInput()
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <BrowserRouter>
        <MaterialUIControllerProvider>
            <App />
        </MaterialUIControllerProvider>
    </BrowserRouter>,
);

// ReactDOM.render(
//     <BrowserRouter>
//         <MaterialUIControllerProvider>
//             <App />
//         </MaterialUIControllerProvider>
//     </BrowserRouter>,
//   document.getElementById("root")
// );
