import * as React from 'react';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";
import "assets/css/style.css"


const DialogDelet = (props) => {
    return (
        <div>
            <Dialog open={props.show} onClose={props.close}>
                <DialogTitle>
                    {"Rechnung löschen"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Sind Sie sich sicher, dass Sie das Objekt löschen möchten?
                    </DialogContentText>
                </DialogContent>


                <DialogActions>
                    <Button onClick={props.close}>Abbrechen</Button>
                    <Button onClick={event => props.save(props.row)}>Löschen</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default DialogDelet;

