import * as React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Button from "@mui/material/Button";
import MDInput from "../../components/MDInput";
import Grid from "@mui/material/Grid";
import "assets/css/style.css";
import { useEffect, useState } from "react";
import FormField from "./Component/FormField";
import Card from "@mui/material/Card";
import MDBox from "../../components/MDBox";
import Autocomplete from "@mui/material/Autocomplete";
import selectData from "../../components/selectData";
import Switch from "@mui/material/Switch";
import sales from "../dashboards/sales";
import { siLK } from "@mui/material/locale";
import { api } from "../../assets/boot/axios";
import reactStringReplace from "react-string-replace";

const DialogEdit = (props) => {

  const [state, setState] = useState({
    id: props.row.id,
    firstName: props.row.firstName,
    lastName: props.row.lastName,
    salutation: props.row.salutation,
    street: props.row.street,
    streetNumber: props.row.streetNumber,
    plz: props.row.plz,
    city: props.row.city,
    telephone: props.row.telephone,
    email: props.row.email,
    type: props.row.type,
    pets: props.row.pets,
    dog: props.row.dog,
    adults: props.row.adults,
    children: props.row.children,
    contractDate: props.row.contractDate,
    rentStart: props.row.rentStart,
    rentEnd: props.row.rentEnd,
    pricePerNight: props.row.pricePerNight,
    priceRent: props.row.priceRent,
    cleaning: props.row.cleaning,
    amountOfDog: props.row.amountOfDog,
    pricePerDog: props.row.pricePerDog,
    amountOfLaundry: props.row.amountOfLaundry,
    pricePerLaundry: props.row.pricePerLaundry,
    kurtaxe: props.row.kurtaxe,
    deposit: props.row.deposit,
    restPayment: props.row.restPayment,
    stillOpen: props.row.stillOpen,
    reduction: props.row.reduction,
    firstPayment: props.row.firstPayment,
    secondPayment: props.row.secondPayment,
    thirdPayment: props.row.thirdPayment,
  });

  const [nights, setNight] = useState({
    nights: props.row.nights,
  });
  const [priceRent, setPriceRent] = useState({
    priceRent: props.row.priceRent,
  });
  const [finalPrice, setFinalPrice] = useState({
    finalPrice: props.row.finalPrice,
  });
  const [restPayment, setRestPayment] = useState({
    restPayment: props.row.restPayment,
  });
  const [stillOpen, setStillOpen] = useState({
    stillOpen: props.row.stillOpen,
  });
  const [sendAlertState, setSendAlertState] = useState(false);
  const [falseValue, setFalseValue] = useState({});

  const [validate, setValidate] = React.useState({
    firstName: true,
    lastName: true,
    salutation: true,
    street: true,
    streetNumber: true,
    plz: true,
    city: true,
    telephone: true,
    email: true,
    type: true,
    pets: true,
    dog: true,
    adults: true,
    children: true,
    contractDate: true,
    rentStart: true,
    rentEnd: true,
    pricePerNight: true,
    cleaning: true,
    amountOfDog: true,
    pricePerDog: true,
    amountOfLaundry: true,
    pricePerLaundry: true,
    kurtaxe: true,
    reduction: true,
    nights: true,
    firstPayment: true,
    secondPayment: true,
    thirdPayment: true,
  });
  const [updateInput, setUpdateInput] = useState({
    name: "",
    type: "",
    value: null,
    state: false,
  });

  let sendAlert;
  let amountDogs;
  let priceDogs;
  let webSpacing;
  if (state.pets) {
    amountDogs = (
      <Grid display="flex" alignItems="center" item xs={12} sm={6}>
        <FormField
          type="number"
          label="Anzahl der Hunde"
          name="amountOfDog"
          onChange={handleChange}
          value={state.amountOfDog}
          variant="standard"
          {...(props.moneyOnly ? { disabled: true } : {})}
        />
      </Grid>
    );
    priceDogs = (
      <Grid item xs={12} sm={6}>
        <FormField
          type="number"
          label="Pro Hund €"
          name="pricePerDog"
          onChange={handleChange}
          value={state.pricePerDog}
          variant="standard"
          {...(props.moneyOnly ? { disabled: true } : {})}
        />
      </Grid>
    );
  }else{
    amountDogs = <Grid display="flex" alignItems="center" item xs={12} sm={6}></Grid>;
  }
  // Wenn Webansicht ist
  if(window.innerHeight > 700){
    webSpacing = <Grid display="flex" alignItems="center" item xs={12} sm={12}></Grid>;
  }
  if (sendAlertState) {
    let falseValueKeys = falseValue;
    let falseValueRightLangueage = [];
    falseValueKeys.forEach((key) => {
      switch (key) {
        case "nights":
          if (!falseValueRightLangueage.includes("Nächte")) {
            falseValueRightLangueage.push("Nächte");
          }
          break;
        case "firstName":
          if (!falseValueRightLangueage.includes("Vorname")) {
            falseValueRightLangueage.push("Vorname");
          }
          break;
        case "lastName":
          if (!falseValueRightLangueage.includes("Nachname")) {
            falseValueRightLangueage.push("Nachname");
          }
          break;
        case "street":
          if (!falseValueRightLangueage.includes("Straße")) {
            falseValueRightLangueage.push("Straße");
          }
          break;
        case "streetNumber":
          if (!falseValueRightLangueage.includes("Hausnummer")) {
            falseValueRightLangueage.push("Hausnummer");
          }
          break;
        case "plz":
          if (!falseValueRightLangueage.includes("PLZ")) {
            falseValueRightLangueage.push("PLZ");
          }
          break;
        case "city":
          if (!falseValueRightLangueage.includes("Stadt")) {
            falseValueRightLangueage.push("Stadt");
          }
          break;
        case "email":
          if (!falseValueRightLangueage.includes("Email")) {
            falseValueRightLangueage.push("Email");
          }
          break;
        case "adults":
          if (!falseValueRightLangueage.includes("Erwachsene")) {
            falseValueRightLangueage.push("Erwachsene");
          }
          break;
        case "children":
          if (!falseValueRightLangueage.includes("Kinder")) {
            falseValueRightLangueage.push("Kinder");
          }
          break;
        case "rentStart":
          if (!falseValueRightLangueage.includes("Mietbeginn")) {
            falseValueRightLangueage.push("Mietbeginn");
          }
          break;
        case "rentEnd":
          if (!falseValueRightLangueage.includes("Mietende")) {
            falseValueRightLangueage.push("Mietende");
          }
          break;
        case "pricePerNight":
          if (!falseValueRightLangueage.includes("Preis pro Nacht")) {
            falseValueRightLangueage.push("Preis pro Nacht");
          }
          break;
        case "kurtaxe":
          if (!falseValueRightLangueage.includes("Kurtaxe")) {
            falseValueRightLangueage.push("Kurtaxe");
          }
          break;
        case "firstPayment":
          if (!falseValueRightLangueage.includes("1.Zahlung")) {
            falseValueRightLangueage.push("1.Zahlung");
          }
        case "secondPayment":
          if (!falseValueRightLangueage.includes("2.Zahlung")) {
            falseValueRightLangueage.push("2.Zahlung");
          }
        case "thirdPayment":
          if (!falseValueRightLangueage.includes("3.Zahlung")) {
            falseValueRightLangueage.push("3.Zahlung");
          }
          break;
        default:
          break;
      }
    });

    let string = JSON.stringify(falseValueRightLangueage);
    let newString = reactStringReplace(
      string,
      /\"\,(\w*)\"|\"(\w*)\]|\[(\w*)\"/,
      (match, i) => <span key={i}>, </span>
    );
    sendAlert = (
      // <MDAlert color="error" children>
      <Card style={{ backgroundColor: "#d22525" }}>
        <MDBox component="form" py={3} px={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <p style={{ fontSize: "1rem", color: "white" }}>
                Folgende Felder wurden nicht richtig ausgefüllt:
              </p>
            </Grid>
            <Grid item xs={12} sm={12}>
              <p style={{ fontSize: "0.9rem", color: "white" }}>{newString}</p>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    );
  }
  function setToday() {
    let date = new Date();
    let dd = date.getDate();
    let mm = date.getMonth() + 1;
    let yyyy = date.getFullYear();
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (dd < 10) {
      dd = "0" + dd;
    }
    let today = yyyy + "-" + mm + "-" + dd;
    return today;
  }
  function validateField(fieldName, value, type) {
    let valideCheck;
    let valideCheckToday;
    let today = setToday();

    switch (type) {
      case "email":
        valideCheck = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        setUpdateInput({
          ...updateInput,
          state: valideCheck === null ? false : true,
        });
        break;
      case "number":
        if (fieldName === "amountOfDog" || fieldName === "pricePerDog") {
          setUpdateInput({
            ...updateInput,
            state: value.length >= 1 || value != 0,
          });
        } else if (fieldName === "adults") {
          setUpdateInput({
            ...updateInput,
            state: value >= 1,
          });
        } else {
          setUpdateInput({
            ...updateInput,
            state: value >= 0,
          });
        }
        break;
      case "date":
        valideCheck = value.match(
          /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/i
        );
        valideCheckToday = value < today;
        if (valideCheckToday === false && valideCheck != null) {
          valideCheck = true;
        } else {
          valideCheck = false;
        }
        setUpdateInput({
          ...updateInput,
          state: valideCheck === false ? false : true,
        });
        break;
      case "text":
        setUpdateInput({
          ...updateInput,
          state: value.length >= 1,
        });
        break;
      default:
        break;
    }
  }

  function handleChange(evt) {
    const fileValue = evt.target.value;
    const fileName = evt.target.name;
    const fileType = evt.target.type;

    setUpdateInput({
      ...updateInput,
      type: fileType,
      value: fileValue,
      name: fileName,
    });
    setState({
      ...state,
      [evt.target.name]: fileValue,
    });
  }

  function handleChangeSelect(newValue, name) {
    if (newValue === "Lüttel") {
      newValue = "Luettel";
    }
    setState({
      ...state,
      [name]: newValue,
    });
  }

  function handleChangeSwitch(newValue, name) {
    setState({
      ...state,
      [name]: !newValue,
    });
  }

  function SetNight() {
    let differenceInTime =
      new Date(state.rentEnd).getTime() - new Date(state.rentStart).getTime();
    let differenceInDays = differenceInTime / (1000 * 3600 * 24);
    setNight({ nights: differenceInDays });
  }

  function SetPriceRent() {
    let newPriceRent = state.pricePerNight * parseInt(nights.nights);
    setPriceRent({ priceRent: newPriceRent.toFixed(2) });
  }

  function valueCheck() {
    let falseValue = [];

    setSendAlertState(false);
    if (nights.nights <= 0) {
      falseValue.push("nights");
      setFalseValue([...falseValue, "nights"]);
    }
    Object.keys(validate).forEach((key) => {
      if (!validate[key]) {
        if (key === "amountOfDog" || key === "pricePerDog") {
          if (state.pets) {
            falseValue.push(key);
            setFalseValue([...falseValue, key]);
          }
        } else {
          falseValue.push(key);
          setFalseValue([...falseValue, key]);
        }
      }
    });

    if (falseValue.length === 0) {
      return props.save(state, props.row);
      sendAlert = null;
    } else {
      setSendAlertState(true);
    }
  }

  function getFullPrice() {
    let bookValues = {
      amountOfDog: state.amountOfDog,
      pricePerDog: state.pricePerDog,
      amountOfLaundry: state.amountOfLaundry,
      pricePerLaundry: state.pricePerLaundry,
      kurtaxe: state.kurtaxe,
      priceRent: priceRent.priceRent,
      cleaning: state.cleaning,
      reduction: state.reduction,
      firstPayment: state.firstPayment,
      secondPayment: state.secondPayment,
      thirdPayment: state.thirdPayment,
    };
    api
      .post(`/booking/getFinalPriceWithBooking/`, bookValues)
      .then((response) => {
        setFinalPrice({ finalPrice: parseFloat(response.data[0]).toFixed(2) });
        setStillOpen({ stillOpen: Math.round(response.data[2] * 100) / 100 });
        setRestPayment({ restPayment: parseFloat(response.data[1]).toFixed(2) });
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  function SetCalcPrice() {
    let newFinalPrice = finalPrice.finalPrice;
    let newStillOpen = stillOpen.stillOpen;
    let newRestPayment = restPayment.restPayment;
    setState({
      ...state,
      finalPrice: newFinalPrice,
      stillOpen: newStillOpen,
      restPayment: newRestPayment,
    });
  }

  useEffect(() => {
    validateField(updateInput.name, updateInput.value, updateInput.type);
  }, [state]);

  useEffect(() => {
    if(state.rentStart > state.rentEnd){
      setState({...state, rentEnd: state.rentStart})
    }
    SetNight();
  }, [state.rentStart, state.rentEnd]);

  useEffect(() => {
    SetPriceRent();
  }, [state.pricePerNight, nights.nights]);
  useEffect(() => {
    if (state.pets === false) {
      setState({
        ...state,
        amountOfDog: 0,
      });
    }
  }, [state.pets]);
  useEffect(() => {
    getFullPrice();
  }, [state, priceRent.priceRent]);

  //TODO
  useEffect(() => {
    if (props.show) {
      getFullPrice();
    }
  }, [props.show]);

  useEffect(() => {
    if (updateInput.name != "") {
      setValidate({
        ...validate,
        [updateInput.name]: updateInput.state,
      });
    }
  }, [updateInput]);
  useEffect(() => {
    SetCalcPrice();
  }, [finalPrice.finalPrice, stillOpen.stillOpen, restPayment.restPayment]);

  return (
    <div>
      <Dialog open={props.show} onClose={props.close}>
        <DialogTitle>Vertrag bearbeiten</DialogTitle>
        {sendAlert}
        <DialogContent component="form">
          {<>
            <Card id="info-person" sx={{ overflow: "visible" }}>
              <MDBox p={3}>
                <DialogContentText>Persönliche Daten</DialogContentText>
              </MDBox>
              <MDBox component="form" pb={3} px={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <Autocomplete
                        options={selectData.salutation}
                        renderInput={(params) => (
                            <FormField
                                {...params}
                                label="Anrede"
                                name="salutation"
                                InputLabelProps={{ shrink: true }}
                                {...(props.moneyOnly ? { disabled: true } : {})}
                            />
                        )}
                        value={state.salutation}
                        onChange={(event: any, newValue: string | null) => {
                          handleChangeSelect(newValue, "salutation");
                        }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormField
                        label="Vorname"
                        name="firstName"
                        onChange={handleChange}
                        value={state.firstName}
                        {...(props.moneyOnly ? { disabled: true } : {})}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormField
                        label="Nachname"
                        name="lastName"
                        onChange={handleChange}
                        value={state.lastName}
                        {...(props.moneyOnly ? { disabled: true } : {})}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormField
                        label="Straße"
                        name="street"
                        onChange={handleChange}
                        value={state.street}
                        {...(props.moneyOnly ? { disabled: true } : {})}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormField
                        label="Hausnr"
                        name="streetNumber"
                        onChange={handleChange}
                        value={state.streetNumber}
                        {...(props.moneyOnly ? { disabled: true } : {})}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormField
                        type="number"
                        label="PLZ"
                        name="plz"
                        onChange={handleChange}
                        value={state.plz}
                        {...(props.moneyOnly ? { disabled: true } : {})}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormField
                        label="Stadt"
                        name="city"
                        onChange={handleChange}
                        value={state.city}
                        {...(props.moneyOnly ? { disabled: true } : {})}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                        type="tel"
                        label="Handynummer"
                        name="telephone"
                        onChange={handleChange}
                        value={state.telephone}
                        variant="standard"
                        {...(props.moneyOnly ? { disabled: true } : {})}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                        type="email"
                        label="Email"
                        name="email"
                        onChange={handleChange}
                        value={state.email}
                        {...(props.moneyOnly ? { disabled: true } : {})}
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </Card>

            <Card id="info-resort" sx={{ overflow: "visible", mt: '1rem'}}>
              <MDBox p={3}>
                <DialogContentText>Ferienwohnung</DialogContentText>
              </MDBox>
              <MDBox component="form" pb={3} px={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <Autocomplete
                        defaultValue="1"
                        options={selectData.typHouse}
                        renderInput={(params) => (
                            <FormField
                                {...params}
                                label="Ferienhaus"
                                InputLabelProps={{ shrink: true }}
                                {...(props.moneyOnly ? { disabled: true } : {})}
                            />
                        )}
                        value={state.type}
                        onChange={(event: any, newValue: string | null) => {
                          handleChangeSelect(newValue, "type");
                        }}
                    />
                  </Grid>

                  <Grid display="flex" alignItems="center" item xs={12} sm={6}>
                    <MDBox>
                      <Switch
                          checked={state.pets}
                          onChange={() => handleChangeSwitch(state.pets, "pets")}
                          {...(props.moneyOnly ? { disabled: true } : {})}
                      />
                    </MDBox>
                    <MDBox width="80%" ml={0.5}>
                      <DialogContentText>Haustiere</DialogContentText>
                    </MDBox>
                  </Grid>
                  {amountDogs}
                  <Grid item xs={12} sm={6}>
                    <FormField
                        type="number"
                        label="Erwachsene"
                        name="adults"
                        onChange={handleChange}
                        value={state.adults}
                        variant="standard"
                        {...(props.moneyOnly ? { disabled: true } : {})}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                        type="number"
                        label="Kinder"
                        name="children"
                        onChange={handleChange}
                        value={state.children}
                        variant="standard"
                        {...(props.moneyOnly ? { disabled: true } : {})}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                        type="date"
                        label="Vertragsdatum"
                        name="contractDate"
                        onChange={handleChange}
                        value={state.contractDate}
                        variant="standard"
                        {...(props.moneyOnly ? { disabled: true } : {})}
                    />
                  </Grid>
                  {webSpacing}
                  <Grid item xs={12} sm={4}>
                    <FormField
                        type="date"
                        label="Mietbeginn"
                        name="rentStart"
                        onChange={handleChange}
                        value={state.rentStart}
                        variant="standard"
                        {...(props.moneyOnly ? { disabled: true } : {})}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormField
                        type="date"
                        label="Mietende"
                        name="rentEnd"
                        onChange={handleChange}
                        value={state.rentEnd}
                        variant="standard"
                        {...(props.moneyOnly ? { disabled: true } : {})}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormField
                        type="number"
                        label="Nächte"
                        name="nights"
                        value={nights.nights}
                        variant="standard"
                        disabled
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </Card>

            <Card id="info-price" sx={{ overflow: "visible", mt: '1rem'}}>
              <MDBox p={3}>
                <DialogContentText>Preise</DialogContentText>
              </MDBox>
              <MDBox component="form" pb={3} px={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <FormField
                        type="number"
                        label="Pro Nacht €"
                        name="pricePerNight"
                        onChange={handleChange}
                        value={state.pricePerNight}
                        variant="standard"
                        {...(props.moneyOnly ? { disabled: true } : {})}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                        type="number"
                        label="Mietpreis €"
                        name="priceRent"
                        onChange={handleChange}
                        value={priceRent.priceRent}
                        variant="standard"
                        disabled
                    />
                  </Grid>
                  {priceDogs}
                  <Grid item xs={12} sm={6}>
                    <FormField
                        type="number"
                        label="Reinigung €"
                        name="cleaning"
                        onChange={handleChange}
                        value={state.cleaning.toFixed(2)}
                        variant="standard"
                        disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                        type="number"
                        label="Pro Wäschepack €"
                        name="pricePerLaundry"
                        onChange={handleChange}
                        value={state.pricePerLaundry.toFixed(2)}
                        variant="standard"
                        disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                        type="number"
                        label="Anzahl Wäschepack"
                        name="amountOfLaundry"
                        onChange={handleChange}
                        value={state.amountOfLaundry}
                        variant="standard"
                        {...(props.moneyOnly ? { disabled: true } : {})}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                        type="number"
                        label="Kurtaxe €"
                        name="kurtaxe"
                        onChange={handleChange}
                        value={state.kurtaxe}
                        variant="standard"
                        {...(props.moneyOnly ? { disabled: true } : {})}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                        type="number"
                        label="Rabat %"
                        name="reduction"
                        onChange={handleChange}
                        value={state.reduction}
                        variant="standard"
                        {...(props.moneyOnly ? { disabled: true } : {})}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                        type="number"
                        label="Anzahlung €"
                        name="deposit"
                        onChange={handleChange}
                        value={state.deposit}
                        variant="standard"
                        {...(props.moneyOnly ? { disabled: true } : {})}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                        type="number"
                        label="Gesamter Rechnungsbetrag €"
                        name="finalPrice"
                        value={finalPrice.finalPrice}
                        variant="standard"
                        disabled
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </>}
          <Card id="info-payment" sx={{ overflow: "visible", mt: '1rem'}}>
            <MDBox p={3}>
              <DialogContentText>Gezahlt/Offen</DialogContentText>
            </MDBox>
            <MDBox component="form" pb={3} px={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <FormField
                    type="number"
                    label="1.Zahlung €"
                    name="firstPayment"
                    value={state.firstPayment}
                    variant="standard"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    type="number"
                    label="2.Zahlung €"
                    name="secondPayment"
                    onChange={handleChange}
                    value={state.secondPayment}
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    type="number"
                    label="3.Zahlung €"
                    name="thirdPayment"
                    onChange={handleChange}
                    value={state.thirdPayment}
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={0} sm={6}></Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    type="number"
                    label="Restzahlung €"
                    name="restPayment"
                    value={restPayment.restPayment}
                    variant="standard"
                    disabled
                  />
                </Grid>
              </Grid>
            </MDBox>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.close}>Abbrechen</Button>
          <Button onClick={valueCheck}>Speichern</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DialogEdit;
