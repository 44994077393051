/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import DefaultCell from "./components/DefaultCell";
import { api } from "../../../assets/boot/axios";
import Icon from "@mui/material/Icon";

function Sales() {
  const [data, setdata] = useState([])
  const getDataFor = [2023,2022]

  function formatInEUR(value) {
    return new Intl.NumberFormat('de-De', {
      style: 'currency',
      currency: 'EUR',
    }).format(value);
  }
  function createHouseData(houseName, resp) {
    const year = resp.year
    let tableFormat = {
      "Umsatz": formatInEUR(resp.totalEarnings),
      "Preis aller Übernachtungen (ohne Zusatzleistungen)": formatInEUR(resp.totalEarningsWithoutExtras),
      "Offene Posten": formatInEUR(resp.restPayment),
      "Buchungen": (resp.bookingsAmounts ? resp.bookingsAmounts : 0) + " Stk",
      "Tier Umsatz": formatInEUR(resp.animalEarnings),
      "Tiere": (resp.animalAmount ? resp.animalAmount : 0) + " Stk",
      "Gästebeträge (Kurtaxe) Umsatz": formatInEUR(resp.guestEarnings),
      "Gäste": (resp.guestAmount ? resp.guestAmount : 0) + " Stk",
      "Wäschepakete Umsatz": formatInEUR(resp.laundryEarnings),
      "Wäschepakete":(resp.laundryAmount ? resp.laundryAmount : 0) + " Stk",
    }
    const columns = [
      { Header: "", accessor: "key", width: "80%" },
      { Header: "", accessor: "value" },
    ]

    //turn obj into list of react elements
    const rows = Object.entries(tableFormat).map((item) => {
      if (item[0].includes('year') || item[0].includes('house')) return;
      return {
        key: <MDTypography variant="button" fontWeight="medium">{item[0]}</MDTypography>,
        value: <DefaultCell>{item[1] ? item[1].toString() : ''}</DefaultCell>,
      }
    })

    //builds obj fitting for ui
    return {
      name: `Haus ${houseName} ${year}`,
      id: houseName+year,
      columns,
      rows,
    }
  }

  async function setLoopData() {
    let i=0;
    let rowDataList=[]
    for(i;i< getDataFor.length;i++){
      //use for and await, so we get all the data before moving on.
      const response = await api.get('/report/' + getDataFor[i])
      rowDataList.push({
        ...response,
      })
    }
    return rowDataList
  }
  useEffect( () => {
    //useEffect must return a function to stop itself to prevent an infinity loop
    //so it need to call an asycn function rader then itself being one
    async function fetchData() {
      await setLoopData()
        .then(resp => {
          //get server request and return the data
          return resp.map((x) => x.data)
        }).then((list) => {
          //for each obj we need react table element
          //so we call the format function
        const newDataSet = []
        list.forEach((obj) => {
          Object.entries(obj).map((x) => {
            newDataSet.push(createHouseData(x[0],  x[1]))
          })
        })
          //we set all the data at the same time for rendering
        setdata(newDataSet)
      }).catch((e) => {
            console.error(e)
          })
    }

    //call the async function
    fetchData()
  }, []);


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          {data.length === 0 && (
              <Grid key={"No Data"} item xs={12} md={6}>
                <Card>
                  <MDTypography variant="h6" fontWeight="medium" style={{padding: '2rem'}}>
                    {"Einer deiner Datensätze ist unvollständig. Bitte korrigieren fehlende Informationn unter Buchungen"} <Icon color="info" fontSize='small'>flag</Icon>
                  </MDTypography>
                </Card>
              </Grid>
              )}
          {data && (
            data.map((item) => {
              return (
                <Grid key={item.id} item xs={12} md={6}>
                  <Card>
                    <MDBox pt={3} px={3}>
                      <MDTypography variant="h6" fontWeight="medium">
                        {item.name}
                      </MDTypography>
                    </MDBox>
                    <MDBox py={1}>
                      <DataTable
                        table={item}
                        entriesPerPage={false}
                        showTotalEntries={false}
                        isSorted={false}
                        noEndBorder
                      />
                    </MDBox>
                  </Card>
                </Grid>
              )
            })
          )}
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
export default Sales;
